<header id="globalHeader" class="global-header header">
  <div class="header-div">
    <div (click)="goToHome();" class="logo-div">
      <img src="../../../assets/logo/textw_250-100px.png" alt="" class="logo" />
    </div>
    <div class="page-title-div">
      <h1 class="page-title">{{pageTitle}}</h1>
    </div>
    <div class="spacer"></div>
    <div class="user-section">
      <!-- <mat-icon>notifications_none</mat-icon> -->
      <div class="profile-name" [matMenuTriggerFor]="menu">
        <div class="avatar">
          <img src="../../assets/logo/avatar.png" />
        </div>
      </div>
      <mat-menu #menu="matMenu" xPosition="before">
        <div class="name-email">
          <div class="name" [title]="userDetails.userName">{{ userDetails.userName }}</div>
          <div class="email" [title]="userDetails.userEmail">{{ userDetails.userEmail }}</div>
        </div>
        <button style="min-width: 170px" mat-menu-item (click)="userProfile()">
          <mat-icon>person_outline</mat-icon>
          <span>User Profile</span>
        </button>
        <button style="min-width: 170px" mat-menu-item (click)="userLogout()">
          <mat-icon>logout</mat-icon>
          <span>Logout</span>
        </button>
      </mat-menu>
    </div>
  </div>
</header>
<nav class="global-nav mat-elevation-z6">
  <app-side-menu></app-side-menu>
</nav>

<main class="global-content" id="main-content">
  <router-outlet></router-outlet>
</main>
