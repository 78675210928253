import { gql } from 'apollo-angular';

const START_CRAWL_CYCLE_RESPONSE = gql`
  {
    startUrl
    isScreenShotEnabled
    crawlCycleType
    status
    createdByUserId
    createdAtTimeStamp
    updatedAtTimeStamp
    noOfDomainsCrawlFinished
    noOfDomainsFailed
    noOfDomainsFinished
    noOfDomainsFound
    noOfDomainsSuspended
    totalNumberOfUrlsCrawlFinished
    totalNumberOfUrlsFailed
    totalNumberOfUrlsFinished
    totalNumberOfUrlsFound
    totalNumberOfUrlsSuspended
    id
    revisionId
  }
`;

export abstract class GraphQLQueries {
  public static authenticationMutations = {
    USER_LOGIN: gql`
      mutation userLoginMutation($email: String!, $password: String!) {
        userLoginMutation(email: $email, password: $password) {
          success
          accessToken
          refreshToken
          userId
        }
      }
    `,
    USER_LOGOUT: gql`
      mutation userLogoutMutation($userId: String!) {
        userLogoutMutation(userId: $userId) {
          success
        }
      }
    `,
    TOKEN_REFRESH: gql`
      mutation tokenRefreshMutation($refreshToken: String!) {
        tokenRefreshMutation(refreshToken: $refreshToken) {
          success
          accessToken
          refreshToken
        }
      }
    `,
  };

  public static crawlerMutation = {
    GET_CRAWL_CYCLES: gql`
      query getCrawlCycles(
        $first: Int!
        $offset: Int!
        $crawlTypeList: [CrawlCycleType!] = null
        $statusList: [CrawlCycleStatus!] = null
      ) {
        getCrawlCycles(
          first: $first
          offset: $offset
          crawlTypeList: $crawlTypeList
          statusList: $statusList
        ) {
          page {
            totalCount
            first
            offset
          }
          crawlCycles {
            startUrl
            isScreenShotEnabled
            crawlCycleType
            status
            createdByUserId
            createdAtTimeStamp
            updatedAtTimeStamp
            noOfDomainsFound
            noOfDomainsCrawlFinished
            noOfDomainsFailed
            noOfDomainsSuspended
            noOfDomainsFinished
            totalNumberOfUrlsFound
            totalNumberOfUrlsCrawlFinished
            totalNumberOfUrlsFailed
            totalNumberOfUrlsSuspended
            totalNumberOfUrlsFinished
            id
            label
            revisionId
          }
        }
      }
    `,
    GET_ALL_CRAWL_CYCLES_DOMAINS: gql`
      query getAllCrawlDomains(
        $first: Int!
        $offset: Int!
        $statusList: [CrawlDomainStatus!] = null
        $crawlCycleId: String!
      ) {
        getAllCrawlDomains(
          first: $first
          offset: $offset
          statusList: $statusList
          crawlCycleId: $crawlCycleId
        ) {
          page {
            totalCount
            first
            offset
          }
          crawlDomains {
            crawlCycleId
            domainName
            status
            totalNumberOfUrlsFound
            totalNumberOfUrlsCrawlFinished
            totalNumberOfUrlsFailed
            totalNumberOfUrlsSuspended
            totalNumberOfUrlsFinished
            createdAtTimeStamp
            updatedAtTimeStamp
            id
            revisionId
            lastCrawlUrlCrawlFinishedTimestamp
          }
        }
      }
    `,
    DELETE_RESOURCE: gql`
      mutation updateResourceMutation($resourceId: String!) {
        updateResourceMutation(id: $resourceId, status: DELETED) {
          success
        }
      }
    `,
    REQUEST_CRAWL_CYCLESUSPENSION: gql`
      mutation requestCrawlCycleSuspension($crawlCycleId: String!) {
        requestCrawlCycleSuspension(crawlCycleId: $crawlCycleId) {
          status
        }
      }
    `,
    REQUEST_CRAWL_CYCLE_RESUME: gql`
      mutation resumeCrawlCycle($crawlCycleId: String!) {
        resumeCrawlCycle(crawlCycleId: $crawlCycleId) {
          status
        }
      }
    `,

    START_CRAWL_CYCLE: {
      SINGLE_PAGE: gql`
        mutation createSingleUrlCrawlCycle($startUrl: String!,$label:String!) {
          createSingleUrlCrawlCycle(startUrl: $startUrl,label: $label) ${START_CRAWL_CYCLE_RESPONSE}
        }
      `,
      SINGLE_PAGE_WITH_SCREENSHOT: gql`
        mutation createSingleUrlCrawlCycleWithScreenshot($startUrl: String!,$label:String!) {
          createSingleUrlCrawlCycleWithScreenshot(startUrl: $startUrl,label: $label) ${START_CRAWL_CYCLE_RESPONSE}
        }
      `,
      SINGLE_DOMAIN: gql`
        mutation createSingleDomainCrawlCycle($startUrl: String!,$label:String!) {
          createSingleDomainCrawlCycle(startUrl: $startUrl,label: $label) ${START_CRAWL_CYCLE_RESPONSE}
        }
      `,
      SINGLE_DOMAIN_WITH_SCREENSHOT: gql`
        mutation createSingleDomainCrawlCycleWithScreenshot($startUrl: String!,$label:String!) {
          createSingleDomainCrawlCycleWithScreenshot(startUrl: $startUrl,label: $label) ${START_CRAWL_CYCLE_RESPONSE}
        }
      `,
      ALL_URLS: gql`
        mutation createCrawlCycleWithAllUrls($startUrl: String!,$label:String!) {
          createCrawlCycleWithAllUrls(startUrl: $startUrl,label: $label) ${START_CRAWL_CYCLE_RESPONSE}
        }
      `,
      ALL_URLS_WITH_SCREENSHOT: gql`
        mutation createCrawlCycleWithAllUrlsWithScreenshot($startUrl: String!,$label:String!) {
          createCrawlCycleWithAllUrlsWithScreenshot(startUrl: $startUrl,label: $label) ${START_CRAWL_CYCLE_RESPONSE}
        }
      `,
    },
    GET_RESOURCE: gql`
      query GetResource($nodeId: String!) {
        getResource(nodeId: $nodeId) {
          resources {
            uid
            htmlContent
            size
            checksum
            mimeType
            elasticDbId
            cephId
            cephIdThumbnail
            cephIdScreenshot
            metadata
            cephThumbnailError
            cephScreenshotError
            cephOtherError
            createdTime
          }
        }
      }
    `,
    GET_CLEAN_HTML: gql`
      query GetWebpageContent($nodeId: String!) {
        getWebpageContent(nodeId: $nodeId) {
          cleanHtml
        }
      }
    `,
    GET_SIGNED_URL: gql`
      query GetSignedUrl($nodeId: String!) {
        getSignedUrl(nodeId: $nodeId) {
          signedUrl
          signedThumbnailUrl
        }
      }
    `,
    GET_ALL_CRAWL_URLS: gql`
      query CrawlUrls(
        $first: Int!
        $offset: Int!
        $crawlCycleId: String!
        $crawlDomainId: String!
        $statusList: [CrawlUrlStatus!]
      ) {
        getAllCrawlUrls(
          first: $first
          offset: $offset
          crawlCycleId: $crawlCycleId
          crawlDomainId: $crawlDomainId
          statusList: $statusList
        ) {
          crawlUrls {
            nodeId
            url
            crawlCycleId
            crawlDomainId
            status
            createdAtTimeStamp
            updatedAtTimeStamp
            id
            revisionId
            referResourceId
            contentType
          }
          page {
            totalCount
            first
            offset
          }
        }
      }
    `,
    GET_ASSOCIATED_LINKS: gql`
      query GetAllReferencedCrawlUrls(
        $first: Int!
        $offset: Int!
        $crawlCycleId: String!
        $referResourceId: String!
        $statusList: [CrawlUrlStatus!],
        $contentType: CrawlUrlContentType!
      ) {
      getAllReferencedCrawlUrls(
        first: $first
        offset: $offset
        crawlCycleId: $crawlCycleId
        referResourceId: $referResourceId
        statusList: $statusList,
        contentType: $contentType
      ) {
        crawlUrls {
          nodeId
          url
          crawlCycleId
          crawlDomainId
          status
          createdAtTimeStamp
          updatedAtTimeStamp
          id
          revisionId
          referResourceId
          contentType
        }
      }
    }
    `,
  };

  public static userMutations = {
    FORGOT_PASSWORD: gql`
      query generateResetPasswordLink($email: String!) {
        generateResetPasswordLink(email: $email) {
          success
        }
      }
    `,
    VALIDATE_CODE: gql`
      query validateVerificationCode($code: String!) {
        validateVerificationCode(code: $code) {
          success
          userId
          type
        }
      }
    `,
    RESET_PASSWORD: gql`
      mutation resetPasswordMutation(
        $code: String!
        $newPassword: String!
        $userId: String!
      ) {
        resetPasswordMutation(
          code: $code
          newPassword: $newPassword
          userId: $userId
        ) {
          success
        }
      }
    `,
    USER_UPDATE: gql`
      mutation userUpdateMutation(
        $userId: String!
        $email: String
        $name: String
        $phoneNumber: String
        $oldPassword: String
        $newPassword: String
      ) {
        userUpdateMutation(
          userId: $userId
          name: $name
          phoneNumber: $phoneNumber
          email: $email
          oldPassword: $oldPassword
          password: $newPassword
        ) {
          success
        }
      }
    `,
    USER_PROFILE: gql`
      query usersQuery($userId: ID!) {
        usersQuery(id: $userId) {
          edges {
            node {
              name
              email
              phoneNumber
              avatar
              createdAt
              id
              isPasswordChangeRequired
              modifiedAt
              status
              businessUnit {
                id
              }
            }
          }
        }
      }
    `,
    USER_PERMISSIONS: gql`
      query userPermissionsQuery($userId: ID!) {
        usersQuery(id: $userId) {
          edges {
            node {
              roles {
                edges {
                  node {
                    rolePermissions {
                      edges {
                        node {
                          description
                          id
                          code
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
  };

  public static adminQueries = {
    GET_USERS: gql`
      query UsersQuery(
        $status: StatusTypeEnum
        $first: Int
        $invitationStatus: StatusTypeEnum
        $userId: ID
      ) {
        usersQuery(
          status: $status
          first: $first
          invitationStatus: $invitationStatus
          id: $userId
        ) {
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
          edges {
            node {
              email
              createdAt
              name
              status
              phoneNumber
              avatar
              id
              roles {
                edges {
                  node {
                    name
                    id
                  }
                }
              }
            }
          }
        }
      }
    `,
    GET_ALL_ROLES: gql`
      query RoleQuery($status: StatusTypeEnum) {
        roleQuery(status: $status) {
          edges {
            node {
              name
              status
              createdAt
              id
            }
          }
        }
      }
    `,
  };

  public static adminMutations = {
    CREATE_ROLE: gql`
      mutation RoleCreateMutation($businessUnitId: ID!, $name: String!) {
        roleCreateMutation(businessUnitId: $businessUnitId, name: $name) {
          success
          role {
            businessUnit {
              id
            }
            createdAt
            description
            id
            modifiedAt
            name
            status
            rolePermissions {
              edges {
                node {
                  id
                }
              }
            }
          }
        }
      }
    `,
    UPDATE_ROLE: gql`
      mutation RoleUpdateMutation(
        $roleUpdateMutationId: ID!
        $roleUpdateMutationName: String
        $businessUnitId: ID
      ) {
        roleUpdateMutation(
          id: $roleUpdateMutationId
          name: $roleUpdateMutationName
          businessUnitId: $businessUnitId
        ) {
          success
        }
      }
    `,
    DELETE_ROLE: gql`
      mutation RoleUpdateMutation($roleId: ID!) {
        roleDeleteMutation(roleId: $roleId) {
          success
        }
      }
    `,
    ASSIGN_ROLE: gql`
      mutation MapUserRoleMutation($rolesAssigned: [ID]!, $userId: ID!) {
        mapUserRoleMutation(roleIdList: $rolesAssigned, userId: $userId) {
          success
        }
      }
    `,
    REMOVE_ROLE: gql`
      mutation RemoveUserRoleMutation($rolesRemoved: [ID]!, $userId: ID!) {
        removeUserRoleMutation(roleIdList: $rolesRemoved, userId: $userId) {
          success
        }
      }
    `,
    CREATE_USER: gql`
      mutation UserCreateMutation(
        $businessUnit: String!
        $email: String!
        $name: String!
        $phoneNumber: String
        $rolesAssigned: [String]
      ) {
        userCreateMutation(
          businessUnit: $businessUnit
          email: $email
          name: $name
          phoneNumber: $phoneNumber
          rolesAssigned: $rolesAssigned
        ) {
          success
        }
      }
    `,
    UPDATE_USER: gql`
      mutation UserUpdateMutation(
        $userId: String!
        $phoneNumber: String
        $name: String
        $email: String
      ) {
        userUpdateMutation(
          userId: $userId
          phoneNumber: $phoneNumber
          name: $name
          email: $email
        ) {
          success
        }
      }
    `,
    UPDATE_USER_STATUS: gql`
      mutation UserUpdateMutation($userId: String!, $userStatus: String) {
        userUpdateMutation(userId: $userId, userStatus: $userStatus) {
          success
        }
      }
    `,
    DELETE_USER: gql`
      mutation UserDeleteMutation($userId: String!) {
        userDeleteMutation(userId: $userId) {
          success
        }
      }
    `,
  };

  public static searchMutations = {
    GET_ALL_RESULTS: gql`
      query SearchByText(
        $searchText: String
        $searchType: String
        $offset: Int
        $resultsPerPage: Int
      ) {
        SearchByText(
          searchText: $searchText
          searchType: $searchType
          offset: $offset
          resultsPerPage: $resultsPerPage
        ) {
          result {
            searchType
            title
            abstract
            tags {
              confidence
              tagName
            }
            nodeId
            thumbnailUrl
          }
          images {
            imageName
            imageUrl
            confidence
            nodeId
          }
          currentOffset
          totalNumberofResults
          error
        }
      }
    `,
    GET_NODE_DETAILS: gql`
      query ResourceDetails($searchType: String, $nodeId: String!) {
        ResourceDetails(searchType: $searchType, nodeId: $nodeId) {
          searchType
          title
          contents
          thumbnailUrl
          imageUrl
          createdOn
          exif
          error
        }
      }
    `,
    GET_TAG_DETAILS: gql`
      query TagDetails($nodeId: String, $offset: Int, $resultsPerPage: Int) {
        TagDetails(
          nodeId: $nodeId
          offset: $offset
          resultsPerPage: $resultsPerPage
        ) {
          currentOffset
          tags {
            tagName
            confidence
          }
          totalNumberofResults
          error
        }
      }
    `,
    GET_URL_DETAILS: gql`
      query UrlDetails($nodeId: String, $offset: Int, $resultsPerPage: Int) {
        UrlDetails(
          nodeId: $nodeId
          offset: $offset
          resultsPerPage: $resultsPerPage
        ) {
          urls {
            createdOn
            version
            lastSeen
            url
          }
          currentOffset
          totalNumberofResults
          error
        }
      }
    `,
    RECENT_SEARCH: gql`
      query RecentSearch($limit: Int, $skip: Int) {
        recentSearch(skipLimit: { limit: $limit, skip: $skip }) {
          totalCount
          results {
            context
            responseCount
            timestamp
            s {
              searchTerm
            }
            filters {
              domain
              includeCsam
              webpageType
            }
          }
        }
      }
    `,
  };

  public static grapnelSearchMutations = {
    SEARCH_WEBPAGES_BY_KEYWORD: gql`
      query SearchWebpagesByKeyword($payload: TermSearchPayload!) {
        searchWebpagesByKeyword(payload: $payload) {
          ... on SearchResult {
            totalCount
            results {
              domain
              docCount
              cover {
                createdAt
                nodeId
                url
                title
                highlight {
                  text
                }
              }
            }
          }
          ... on DomainSearchResult {
            totalCount
            results {
              createdAt
              nodeId
              url
              title
              highlight {
                text
              }
              score
            }
          }
        }
      }
    `,
    SEARCH_IMAGES_BY_KEYWORD: gql`
      query SearchImagesByKeyword($payload: TermSearchPayload!) {
        searchImagesByKeyword(payload: $payload) {
          totalCount
          results {
            ... on ImageSearchItem {
              score
              url
              createdAt
              nodeId
              sourceNodeId
              sourceTitle
              isCsam
              width
              height
              domain
            }
            ... on ImageSearchResultDomain {
              score
              url
              createdAt
              nodeId
              sourceNodeId
              sourceTitle
              isCsam
              width
              height
            }
          }
        }
      }
    `,
    SEARCH_IMAGES_BY_NODE_ID: gql`
      query SearchImagesByNodeId($nodeId: String!, $filters: ImageFilter!) {
        searchImagesByNodeId(nodeId: $nodeId, filters: $filters) {
          totalCount
          results {
            ... on ImageSearchItem {
              score
              url
              createdAt
              nodeId
              sourceNodeId
              sourceTitle
              isCsam
              width
              height
              domain
            }
            ... on ImageSearchResultDomain {
              score
              url
              createdAt
              nodeId
              sourceNodeId
              sourceTitle
              isCsam
              width
              height
            }
          }
        }
      }
    `,
    SEARCH_IMAGES_BY_IMAGES: gql`
      mutation SearchImagesByImage($image: Upload!, $filters: ImageFilter!) {
        searchImagesByImage(image: $image, filters: $filters) {
          totalCount
          results {
            ... on ImageSearchItem {
              score
              url
              createdAt
              nodeId
              sourceNodeId
              sourceTitle
              isCsam
              width
              height
              domain
            }
            ... on ImageSearchResultDomain {
              score
              url
              createdAt
              nodeId
              sourceNodeId
              sourceTitle
              isCsam
              width
              height
            }
          }
        }
      }
    `,
    WEBPAGESEARCH_OVERVIEW: gql`
      query WebpageSearchOverview($payload: TermSearchPayload!) {
        webpageSearchOverview(payload: $payload) {
          domain {
            label
            docCount
          }
          webpageType {
            label
            docCount
          }
        }
      }
    `,
    IMAGESEARCH_OVERVIEW: gql`
      query ImageSearchOverview($payload: TermSearchPayload!) {
        imageSearchOverview(payload: $payload) {
          domain {
            label
            docCount
          }
          webpageType {
            label
            docCount
          }
        }
      }
    `
    };

    public static csamMutations = {
      REPORT_WEBPAGE_AS_CSAM: gql`
        mutation UpdateWebpageCsam($nodeId: String!, $csam: Boolean!) {
          updateWebpageCsam(nodeId: $nodeId, csam: $csam)
        }
      `,
      REPORT_IMAGE_AS_CSAM: gql`
        mutation UpdateImageCsam($nodeId: String!, $csam: Boolean!) {
          updateImageCsam(nodeId: $nodeId, csam: $csam) {
            success
          }
        }
      `,
  };
}
