import { HttpHeaders } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  ApolloLink,
  gql,
  InMemoryCache,
  Observable,
} from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { environment } from './../environments/environment';
import { AuthService } from './services/auth.service';
import { SpinnerService } from './services/spinner.service';
import { Router } from '@angular/router';

const uri = environment.apiEndPoint;
@NgModule({
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory(httpLink: HttpLink) {
        const http = httpLink.create({ uri: uri });
        const middleware = new ApolloLink((operation, forward) => {
          operation.setContext({
            headers: new HttpHeaders().set(
              'Authorization',
              `Bearer ${localStorage.getItem('ACCESS_TOKEN') || null}`
            ),
          });
          return forward(operation);
        });
        const errorLink = onError(
          ({ response, operation, graphQLErrors, networkError, forward }) => {
            if (graphQLErrors) {
              return new Observable((observer) => {
                graphQLErrors.map(
                  ({ message, locations, path, extensions }) => {
                    console.log(
                      `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}, Status: ${extensions.status}`
                    );
                    GraphQLModule.spinnerService.requestEnded();
                    GraphQLModule.snackBar.open(message, 'X', {
                      duration: 2000,
                      panelClass: ['snackBar'],
                    });
                    if (message == 'PERMISSION_DENIED') {
                      return GraphQLModule.testRefresh().subscribe(
                        (value: any) => {
                          if (value) {
                            const oldHeader = operation.getContext().headers;
                            operation.setContext({
                              headers: {
                                ...oldHeader,
                                Authorization: `Bearer ${
                                  localStorage.getItem('ACCESS_TOKEN') || null
                                }`,
                              },
                            });
                            const subscriber = {
                              next: observer.next.bind(observer),
                              error: observer.error.bind(observer),
                              complete: observer.complete.bind(observer),
                            };
                            forward(operation).subscribe(subscriber);
                          }
                        }
                      );
                    } else if (extensions.status == 'REFRESH_TOKEN_EXPIRED') {
                      GraphQLModule.userLogout();
                    } else if (extensions.status == 'UNAUTHENTICATED') {
                      GraphQLModule.silentLogout();
                    } else {
                      const subscriber = {
                        next: observer.next.bind(observer),
                        error: observer.error.bind(observer),
                        complete: observer.complete.bind(observer),
                      };
                      return forward(operation).subscribe(subscriber);
                    }
                  }
                );
              });
            }
            if (networkError) console.log(`[Network error]: ${networkError}`);
            return null;
          }
        );
        const typeDefs = gql`
          enum ResourceTypeEnum {
            IRC
            WEBSITE
            OTHER
          }
          enum ResourceStatusEnum {
            ACTIVE
            DISABLED
            DELETED
          }
        `;
        const link = ApolloLink.from([errorLink, middleware, http]);
        return {
          link,
          cache: new InMemoryCache(),
          typeDefs,
        };
      },
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule {
  static testService;
  static snackBar;
  static spinnerService;
  static router;
  constructor(
    private authService: AuthService,
    private snackBar: MatSnackBar,
    private spinnerService: SpinnerService,
    private router: Router
  ) {
    GraphQLModule.testService = this.authService;
    GraphQLModule.snackBar = this.snackBar;
    GraphQLModule.spinnerService = this.spinnerService;
    GraphQLModule.router = router;
  }
  public static testRefresh() {
    return GraphQLModule.testService.refreshToken();
  }

  public static userLogout() {
    return GraphQLModule.testService.logout();
  }

  public static silentLogout() {
    GraphQLModule.testService.doLogoutUser();
    return GraphQLModule.router.navigateByUrl('/auth/login');
  }
}
