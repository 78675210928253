import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { GraphQLQueries } from '../models/graphql.queries';
import { BooleanTypeRequestModel } from '../models/boolean-request.model';
import { Router } from '@angular/router';
import { VerificationTypeModel } from '../models/verification-code.model';
import { UserUpdateMutationModel } from '../models/user-update-mutation.model';
import { UserTypeConnectionModel } from '../models/user-type-connection.model';
import { map } from 'rxjs/operators';
import { UserTypeEdgeModel } from '../models/user-type-edge.model';
import { AuthService } from './auth.service';
import { UserProfile } from '../main/models/user.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private apollo: Apollo, private authService: AuthService) {}

  public forgotPassword(email: string) {
    return this.apollo.query<{
      generateResetPasswordLink: BooleanTypeRequestModel;
    }>({
      query: GraphQLQueries.userMutations.FORGOT_PASSWORD,
      variables: {
        email: email,
      },
    });
  }

  public validateCode(code: string) {
    return this.apollo.query<{
      validateVerificationCode: VerificationTypeModel;
    }>({
      query: GraphQLQueries.userMutations.VALIDATE_CODE,
      variables: {
        code: code,
      },
    });
  }

  public resetPassword(code: string, newPassword: string, userId: string) {
    return this.apollo.mutate<{
      resetPasswordMutation: BooleanTypeRequestModel;
    }>({
      mutation: GraphQLQueries.userMutations.RESET_PASSWORD,
      variables: {
        code: code,
        newPassword: newPassword,
        userId: userId,
      },
    });
  }

  public userUpdate(userProfile: UserProfile) {
    return this.apollo.mutate<{
      userUpdateMutation: UserUpdateMutationModel;
    }>({
      mutation: GraphQLQueries.userMutations.USER_UPDATE,
      variables: {
        userId: this.authService.getUserID(),
        name: userProfile.name,
        email: userProfile.email,
        phoneNumber: userProfile.contactNumber,
        oldPassword: userProfile.oldPassword || null,
        newPassword: userProfile.newPassword || null,
      },
    });
  }

  public getCurrentUserProfile() {
    return this.apollo
      .query<{ usersQuery: UserTypeConnectionModel }>({
        query: GraphQLQueries.userMutations.USER_PROFILE,
        variables: {
          userId: this.authService.getUserID(),
        },
      })
      .pipe(
        map((userData: { data: { usersQuery: any } }) => {
          return userData.data.usersQuery.edges.map(
            (user: UserTypeEdgeModel): UserProfile => {
              return {
                name: user.node.name,
                email: user.node.email,
                contactNumber: user.node.phoneNumber,
              };
            }
          );
        })
      );
  }
}
