import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { SpinnerService } from '../services/spinner.service';
import { UserService } from '../services/user.service';
import { UserProfileComponent } from './user-profile/user-profile.component';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
  private subscriptions = new Subscription();
  public userDetails;
  public pageTitle: string = "";

  constructor(
    private authService: AuthService,
    private spinnerService: SpinnerService,
    private userService: UserService,
    private dialog: MatDialog,
    private router: Router
  ) {
    this.router.events.subscribe(event=>{
      if (event instanceof NavigationEnd) {
        if (this.router.url.includes('/crawlmanagement/crawllist')) {
          this.pageTitle = 'Crawler';
        } else if (this.router.url.includes('/search')) {
          this.pageTitle = 'Search';
        } else if (this.router.url.includes('/admin')) {
          this.pageTitle = "User";
        } else {
          this.pageTitle = '';
        }
      }
    });
  }

  ngOnInit() {
    this.userDetails = this.authService.getUserDetails();
  }

  public userProfile(): void {
    this.spinnerService.requestStarted();
    this.subscriptions.add(
      this.userService.getCurrentUserProfile().subscribe((userProfile) => {
        const currentUserProfile = {
          name: userProfile[0].name,
          email: userProfile[0].email,
          contactNumber: userProfile[0].contactNumber,
        };
        this.dialog.open(UserProfileComponent, {
          data: currentUserProfile,
          panelClass: 'dialog-panel',
          width: '90vw',
        });
        this.spinnerService.requestEnded();
      })
    );
  }

  public userLogout(): void {
    this.authService.logout();
  }

  public goToHome(): void {
    this.router.navigateByUrl('search');
  }
}
