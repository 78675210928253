import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ScrollContainerComponent } from './scroll-container.component';

@NgModule({
  declarations: [ScrollContainerComponent],
  imports: [FormsModule, ReactiveFormsModule, CommonModule],
  exports: [ScrollContainerComponent],
  providers: [],
  bootstrap: [],
})
export class ScrollContainerModule {}
