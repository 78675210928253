import { Component } from "@angular/core";
import { datadogRum } from '@datadog/browser-rum';
import { environment } from 'src/environments/environment'
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent {

  constructor() {
    datadogRum.init(environment.datadog);
    datadogRum.getInternalContext()
  }
}
