import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ConfirmedValidator } from '../../../helper/confirmed.validator';
import { PasswordIndicator } from 'src/app/models/passwordindicator.model';

@Component({
  selector: 'app-password-change',
  templateUrl: './password-change.component.html',
  styleUrls: ['./password-change.component.scss'],
})
export class PasswordChangeComponent implements OnInit {
  @Output() passwordForm: EventEmitter<FormGroup> = new EventEmitter();
  public signUpFormGroup: FormGroup;
  public passwordIndicator: PasswordIndicator;
  public isPasswordVisible: Boolean;
  public isConfirmPasswordVisible: Boolean;

  constructor(private _formBuilder: FormBuilder) {
    this.passwordIndicator = {
      alphabet: false,
      number: false,
      special: false,
      characterLength: false,
    };
    this.isPasswordVisible = false;
    this.isConfirmPasswordVisible = false;
  }

  public ngOnInit(): void {
    this.signUpFormGroup = this._formBuilder.group(
      {
        password: [
          '',
          [
            Validators.required,
            Validators.pattern(
              '^(?=.*[a-zA-Z])(?=.*d)(?=.*[#$^+=!*()@%&]).{10,}$'
            ),
          ],
        ],
        cnfPassword: ['', Validators.required],
      },
      { validator: ConfirmedValidator('password', 'cnfPassword') }
    );
    this.passwordForm.emit(this.signUpFormGroup);
  }
  get password(): FormControl {
    return this.signUpFormGroup.controls.password as FormControl;
  }
  get cnfPassword(): FormControl {
    return this.signUpFormGroup.controls.cnfPassword as FormControl;
  }
  public checkPassword(event: Event): void {
    let value = (<HTMLInputElement>event.target).value;
    let passowrdPattern: PasswordIndicator = {
      alphabet: '.*[a-zA-Z]+.*',
      number: '.*[0-9]+.*',
      special: '.*[#$^+=!*()@%&]+.*',
      characterLength: '^.{10,}$',
    };
    Object.keys(passowrdPattern).forEach((key) => {
      let expr: string = passowrdPattern[key];
      let pattern = new RegExp(expr);
      this.passwordIndicator[key] = pattern.test(value);
    });
  }
}
