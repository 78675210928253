<form
  class="advanced-search-form"
  [formGroup]="userProfileForm"
  (ngSubmit)="onSubmit(userProfileForm)"
>
  <h5 class="title">Manage Your Profile</h5>
  <div class="header">
    <h5>Personal Details</h5>
    <!-- <div class="avatar-icon">
            <img [src]="this.currentUserProfile.avatar" alt="user avatar">
            <button mat-icon-button><mat-icon>edit</mat-icon></button>
        </div> -->
    <h5>Password Reset</h5>
  </div>
  <div class="user-details">
    <div class="personal-details form-container">
      <div class="first-name-field input-field">
        <div class="body-1">Name</div>
        <input
          type="text"
          matInput
          formControlName="name"
          placeholder="Enter your Name"
        />
        <mat-error
          *ngIf="
            userProfileForm.controls.name.touched &&
            userProfileForm.controls.name.dirty &&
            userProfileForm.controls.name.hasError('required')
          "
        >
          Name cannot be <strong>empty</strong>
        </mat-error>
      </div>
      <div class="email-field input-field">
        <div class="body-1">Email</div>
        <input
          type="email"
          matInput
          formControlName="email"
          placeholder="Enter your Email"
        />
        <mat-error
          *ngIf="
            userProfileForm.controls.email.touched &&
            userProfileForm.controls.email.dirty &&
            userProfileForm.controls.email.hasError('required')
          "
        >
          Email cannot be <strong>empty</strong>
        </mat-error>
        <mat-error
          *ngIf="
            userProfileForm.controls.email.touched &&
            userProfileForm.controls.email.dirty &&
            userProfileForm.controls.email.hasError('email')
          "
        >
          Invalid Email
        </mat-error>
      </div>
      <div class="number-field input-field">
        <div class="body-1">Contact Number</div>
        <input
          type="text"
          matInput
          formControlName="contactNumber"
          placeholder="Enter your number"
        />
        <mat-error
          *ngIf="
            userProfileForm.controls.contactNumber.touched &&
            userProfileForm.controls.contactNumber.dirty &&
            userProfileForm.controls.contactNumber.hasError('required')
          "
        >
          Contact Number cannot be <strong>empty</strong>
        </mat-error>
      </div>
    </div>
    <div class="password-reset form-container">
      <div class="current-password-field input-field">
        <div class="body-1">Enter Old Password</div>
        <div class="password-input">
          <input
            [type]="isPasswordVisible ? 'text' : 'password'"
            matInput
            formControlName="password"
            placeholder="Enter your Old Password"
          />
          <button
            class="password-visible"
            mat-icon-button
            (click)="isPasswordVisible = !isPasswordVisible"
          >
            <mat-icon
              *ngIf="!isPasswordVisible"
              color="accent"
              aria-hidden="false"
              aria-label="password"
              >visibility</mat-icon
            >
            <mat-icon
              *ngIf="isPasswordVisible"
              color="accent"
              aria-hidden="false"
              aria-label="password"
              >visibility_off</mat-icon
            >
          </button>
        </div>
      </div>
      <div class="password-change">
        <app-password-change
          (passwordForm)="setPasswordForm($event)"
        ></app-password-change>
      </div>
    </div>
  </div>
  <div class="footer">
    <button
      class="exit-button"
      mat-button
      mat-dialog-close
      color="warn"
      (click)="openSnackBar('Changes Not Saved')"
    >
      <mat-icon>logout</mat-icon>
      <h5>Exit</h5>
    </button>
    <button
      type="submit"
      class="confirm-button"
      mat-raised-button
      mat-dialog-close
      color="primary"
    >
      <h6>Confirm</h6>
    </button>
  </div>
</form>
