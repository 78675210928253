<div class="container">
  <div class="icon-container">
    <div class="app-icon" (click)="goToHome()">
      <img src="../../../assets/logo/iconwhite_100px.png" width="50px" />
    </div>

    <div class="icons">
      <ng-container *ngFor="let item of menu">
        <div
          class="icon-container"
          routerLink="/{{ item.route }}"
          routerLinkActive="active"
          *ngIf="item.show"
          title="{{ item.title }}"
        >
          <mat-icon class="icon">{{ item.name }}</mat-icon>
        </div>
      </ng-container>
    </div>
  </div>
</div>
