import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AuthLoginMutationModel } from '../models/auth-login-mutation.model';
import { AuthTokenRefreshMutationModel } from '../models/auth-token-refresh-mutation.model';
import { BooleanTypeRequestModel } from '../models/boolean-request.model';
import { GraphQLQueries } from '../models/graphql.queries';
import { UserTypeConnectionModel } from '../models/user-type-connection.model';
import { UserTypeEdgeModel } from '../models/user-type-edge.model';
import { SpinnerService } from './spinner.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly ACCESS_TOKEN = 'ACCESS_TOKEN';
  private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';
  private readonly USER_ID = 'USER_ID';
  private readonly BUSINESS_UNIT_ID = 'BUSINESS_UNIT_ID';
  private readonly USER_NAME = 'USER_NAME';
  private readonly USER_EMAIL = 'USER_EMAIL';
  public userPermissions$: BehaviorSubject<Array<string>> = new BehaviorSubject(
    []
  );

  constructor(
    private apollo: Apollo,
    private router: Router,
    private spinnerService: SpinnerService
  ) {
    setInterval(() => {
      if (this.isLoggedIn()) {
        let accessToken = localStorage.getItem(this.ACCESS_TOKEN);
        if (this.tokenExpired(accessToken)) {
          this.refreshToken().subscribe();
        }
      }
    }, 5 * 60 * 1000);
  }
  private tokenExpired(token: string) {
    const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
    return (Math.floor((new Date).getTime() / 1000) + 300) >= expiry;
  }
  public login(email: string, password: string) {
    return this.apollo
      .mutate<{ userLoginMutation: AuthLoginMutationModel }>({
        mutation: GraphQLQueries.authenticationMutations.USER_LOGIN,
        variables: {
          email: email,
          password: password,
        },
      })
      .pipe(
        tap((res) => {
          if (res.data!.userLoginMutation.success) {
            this.doLoginUser(res.data!.userLoginMutation);
          }
        })
      );
  }

  public logout(): void {
    this.apollo
      .mutate<{ userLogoutMutation: BooleanTypeRequestModel }>({
        mutation: GraphQLQueries.authenticationMutations.USER_LOGOUT,
        variables: {
          userId: this.USER_ID,
        },
      })
      .subscribe();
    this.doLogoutUser();
    this.router.navigateByUrl('/auth/login');
  }

  public refreshToken() {
    return new Observable((observable) => {
      this.apollo
        .mutate<{ tokenRefreshMutation: AuthTokenRefreshMutationModel }>({
          mutation: GraphQLQueries.authenticationMutations.TOKEN_REFRESH,
          variables: {
            refreshToken: localStorage.getItem(this.REFRESH_TOKEN),
          },
        })
        .subscribe(
          (res) => {
            if (res.data!.tokenRefreshMutation.success) {
              this.updateTokens(res.data!.tokenRefreshMutation);
              observable.next(true);
            }
            observable.complete();
          },
          () => {
            this.doLogoutUser();
            observable.next(false);
            observable.complete();
          }
        );
    });
  }

  public isLoggedIn(): boolean {
    return !!localStorage.getItem(this.ACCESS_TOKEN);
  }

  private doLoginUser(tokens: AuthLoginMutationModel): void {
    this.spinnerService.requestStarted();
    localStorage.setItem(this.ACCESS_TOKEN, tokens.accessToken);
    localStorage.setItem(this.REFRESH_TOKEN, tokens.refreshToken);
    localStorage.setItem(this.USER_ID, tokens.userId);
    this.apollo
      .query<{ usersQuery: UserTypeConnectionModel }>({
        query: GraphQLQueries.userMutations.USER_PROFILE,
        variables: {
          userId: tokens.userId,
        },
      })
      .subscribe(
        (userData: { data: { usersQuery: any } }) => {
          userData.data.usersQuery.edges.map((user: UserTypeEdgeModel) => {
            if (user.node.businessUnit) {
              localStorage.setItem(
                this.BUSINESS_UNIT_ID,
                user.node.businessUnit.id
              );
            }
            localStorage.setItem(this.USER_NAME, user.node.name);
            localStorage.setItem(this.USER_EMAIL, user.node.email);
            this.spinnerService.resetSpinner();
            this.router.navigateByUrl('/');
          });
        },
        () => this.router.navigateByUrl('/')
      );
  }

  private updateTokens(tokens: AuthTokenRefreshMutationModel): void {
    localStorage.setItem(this.ACCESS_TOKEN, tokens.accessToken);
    localStorage.setItem(this.REFRESH_TOKEN, tokens.refreshToken);
  }

  private doLogoutUser(): void {
    localStorage.removeItem(this.ACCESS_TOKEN);
    localStorage.removeItem(this.REFRESH_TOKEN);
    localStorage.removeItem(this.USER_ID);
    localStorage.removeItem(this.BUSINESS_UNIT_ID);
    localStorage.removeItem(this.USER_NAME);
    localStorage.removeItem(this.USER_EMAIL);
    this.spinnerService.resetSpinner();
  }

  public getUserID(): string {
    return localStorage.getItem(this.USER_ID) || '';
  }

  public getUserDetails() {
    return {
      businessUnitId: localStorage.getItem(this.BUSINESS_UNIT_ID),
      userName: localStorage.getItem(this.USER_NAME),
      userEmail: localStorage.getItem(this.USER_EMAIL),
    };
  }

  public getCurrentUserPermissions() {
    return this.apollo
      .query({
        query: GraphQLQueries.userMutations.USER_PERMISSIONS,
        variables: {
          userId: this.getUserID(),
        },
      })
      .pipe(
        map((permissions: any) =>
          permissions.data.usersQuery.edges[0].node.roles.edges[0].node.rolePermissions.edges.map(
            (e) => e.node.code
          )
        )
      );
  }
}
