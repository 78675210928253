import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-pager',
  templateUrl: './app-pagination.component.html',
  styleUrls: ['./app-pagination.component.scss'],
})
export class PaginationComponent implements OnInit {
  private _pageSize: number;
  public totalNumberOfPages: number;
  public pageNumberList: Array<any> = [];
  public pageIndex: number = 1;

  @Output() pageChanged: EventEmitter<number> = new EventEmitter<number>();
  @Input() totalCount: number;
  @Input() public set pageSize(value: number) {
    this._pageSize = value;
  }

  constructor() {}

  public ngOnInit() {
    this.totalNumberOfPages = Math.ceil(this.totalCount / this._pageSize);
  }

  public ngOnChanges() {
    this.totalNumberOfPages = Math.ceil(this.totalCount / this._pageSize);
    this.doPaging();
  }

  private doPaging(): void {
    this.pageNumberList = [];
    if (!this.totalCount) {
      return;
    }

    const remaining = this.totalCount % this._pageSize;
    const totalNumberOfPages =
      (this.totalCount - remaining) / this._pageSize +
      (remaining === 0 ? 0 : 1);

    if (totalNumberOfPages <= 6) {
      for (let i = 1; i <= totalNumberOfPages; i++) {
        this.pageNumberList.push(i);
      }
    } else {
      this.pageNumberList.push(1);
      const maxPageNum =
        this.pageIndex + 2 < totalNumberOfPages
          ? this.pageIndex + 2
          : totalNumberOfPages;

      if (this.pageIndex < 5) {
        for (let i = 2; i <= 6; i++) {
          this.pageNumberList.push(i);
        }

        this.pageNumberList.push('...');
      } else {
        this.pageNumberList.push('...');

        for (let i = 4; i >= 0; i--) {
          this.pageNumberList.push(maxPageNum - i); // There will always be 5 consecutive numbers, up to maxPageNum
        }

        if (maxPageNum != totalNumberOfPages) {
          this.pageNumberList.push('...');
        }
      }
    }
  }

  public goToPrevPg(): void {
    if (this.pageIndex - 1 >= 1) {
      this.setCurrentPage(this.pageIndex - 1);
      window.scrollTo({top: 0, behavior: 'smooth'});
    }
    
  }

  public goToNextPg(): void {
    if (this.pageIndex + 1 <= this.totalNumberOfPages) {
      this.setCurrentPage(this.pageIndex + 1);
      window.scrollTo({top: 0, behavior: 'smooth'});
    }
  
  }

  public setCurrentPage(page: any): void {
    if (isNaN(page) || page < 1) {
      return;
    }

    if (page !== this.pageIndex) {
      this.pageIndex = page;
      this.pageChanged.emit(page);
      window.scrollTo({top: 0, behavior: 'smooth'});
    }

    this.doPaging();
  }
}
