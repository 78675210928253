import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  private isLogginIn: boolean;
  constructor(private authService: AuthService, private router: Router) {}

  canActivate() {
    this.isLogginIn = this.authService.isLoggedIn();
    if (!this.isLogginIn) {
      this.router.navigate(['/auth/login']);
    }
    return true;
  }
}
