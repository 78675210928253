<form [formGroup]="signUpFormGroup">
    <div class="text-container">
        <p class="password-strength sub-text">
            <span [ngClass]="{'green-warning':passwordIndicator.alphabet}">1 alphabet</span> - <span [ngClass]="{'green-warning':passwordIndicator.number}">1 number</span> - <span [ngClass]="{'green-warning':passwordIndicator.special}">1 special character</span>            -
            <span [ngClass]="{'green-warning':passwordIndicator.characterLength}">Minimum 10 character length</span>
        </p>
    </div>
    <div class="password-reset">
        <div class="password-field">
            <div class="password-input">
                <div>
                    <input [type]="isPasswordVisible? 'text': 'password'" matInput placeholder="Enter your new password" formControlName="password" required (input)="checkPassword($event)">
                </div>
                <div class="password-visible-container">
                    <button class="password-visible" mat-icon-button (click)="isPasswordVisible= !isPasswordVisible;">
                      <mat-icon *ngIf="!isPasswordVisible" color="accent" aria-hidden="false" aria-label="password">visibility</mat-icon>
                      <mat-icon *ngIf="isPasswordVisible" color="accent" aria-hidden="false" aria-label="password">visibility_off</mat-icon>
                    </button>
                </div>
                <mat-error *ngIf="password.touched && password.dirty  && (password.hasError('pattern') || password.hasError('required'))">
                    Please enter a valid password
                </mat-error>
            </div>
        </div>

        <div class="password-field">
            <div class="password-input">
                <input [type]="isConfirmPasswordVisible? 'text': 'password'" matInput placeholder="Confirm your new password" formControlName="cnfPassword" required>
                <button class="password-visible" mat-icon-button (click)="isConfirmPasswordVisible= !isConfirmPasswordVisible;">
                    <mat-icon *ngIf="!isConfirmPasswordVisible" color="accent" aria-hidden="false" aria-label="password">visibility</mat-icon>
                    <mat-icon *ngIf="isConfirmPasswordVisible" color="accent" aria-hidden="false" aria-label="password">visibility_off</mat-icon>
                </button>
                <mat-error *ngIf="cnfPassword.touched  && cnfPassword.dirty  && cnfPassword.hasError('required') && !cnfPassword.hasError('confirmedValidator')">
                    Please enter valid password
                </mat-error>
                <mat-error *ngIf="cnfPassword.touched && cnfPassword.dirty  && !cnfPassword.hasError('required') && cnfPassword.hasError('confirmedValidator')">
                    Please make sure password match
                </mat-error>
            </div>
        </div>
    </div>

</form>
