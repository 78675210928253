import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserProfile } from '../models/user.model';
import { PasswordIndicator } from '../../models/passwordindicator.model';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent {
  public userProfileForm: FormGroup;
  public isPasswordVisible: Boolean = false;
  public passwordIndicator: PasswordIndicator;
  private subscriptions = new Subscription();
  private passwordForm: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public currentUserProfile: UserProfile,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private snackBar: MatSnackBar
  ) {
    this.userProfileForm = this.formBuilder.group({
      name: this.formBuilder.control(
        this.currentUserProfile.name,
        Validators.required
      ),
      email: this.formBuilder.control(
        this.currentUserProfile.email,
        Validators.compose([Validators.required, Validators.email])
      ),
      contactNumber: this.formBuilder.control(
        this.currentUserProfile.contactNumber,
        Validators.required
      ),
      password: this.formBuilder.control(''),
    });
  }

  public setPasswordForm(formGroup: FormGroup) {
    this.subscriptions.add(
      formGroup.valueChanges.subscribe((val) => {
        this.passwordForm = formGroup;
      })
    );
  }

  public onSubmit(data: FormGroup): void {
    this.currentUserProfile = {
      name: this.userProfileForm.controls.name.value,
      email: this.userProfileForm.controls.email.value,
      contactNumber: this.userProfileForm.controls.contactNumber.value,
      oldPassword: this.userProfileForm.controls.oldPassword?.value,
      newPassword: this.passwordForm?.controls.password.value,
    };
    this.userService.userUpdate(this.currentUserProfile).subscribe((res) => {
      this.openSnackBar('User profile updated successfully');
    });
  }

  public openSnackBar(message: string) {
    this.snackBar.open(message, 'X', {
      duration: 2000,
      panelClass: ['snackBar'],
    });
  }
}
