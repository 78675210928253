<div class="pagination-container" *ngIf="totalNumberOfPages > 1">
  <div class="pagination">
    <div class="arrow-wrap" (click)="goToPrevPg()" id="previousPagesButton" aria-label="Previous" [ngClass]="{'inactive': pageIndex == 1}">
      <a role="button">
        <mat-icon>chevron_left</mat-icon>
      </a>
    </div>
    <div class="pages" *ngFor="let pageNo of pageNumberList"
      [ngClass]="{ 'active': pageNo === pageIndex, 'disable': pageNo === '...'}"
      (click)="setCurrentPage(pageNo)">{{pageNo}}</div>
    <div class="arrow-wrap" (click)="goToNextPg()" id="nextPagesButton" aria-label="Next" [ngClass]="{'inactive': pageIndex == totalNumberOfPages}">
      <a role="button">
        <mat-icon>chevron_right</mat-icon>
      </a>
    </div>
  </div>
  <!-- <ul class="pagination">
    <li [ngClass]="{ disable: pageIndex === 1 }">
      <a (click)="goToPrevPg()" id="previousPagesButton" aria-label="Previous" [ngClass]="{'inactive':pageIndex==1}">
        <i class="material-icons navigation-icon">chevron_left</i>
      </a>
    </li>

    <li
      [ngClass]="{ active: pageNo === pageIndex, disable: pageNo === '...' }"
      *ngFor="let pageNo of pageNumberList"
    >
      <a
        class="pagination-list"
        id="page{{ pageNo }}"
        (click)="setCurrentPage(pageNo)"
        >{{ pageNo }}</a
      >
    </li>

    <li [ngClass]="{ disable: pageIndex === totalNumberOfPages }">
      <a (click)="goToNextPg()" id="nextPagesButton" aria-label="Next" [ngClass]="{'inactive':pageIndex==totalNumberOfPages}">
        <i class="material-icons navigation-icon">chevron_right</i>
      </a>
    </li>
  </ul> -->
</div>
