import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate',
})
export class TruncatePipe implements PipeTransform {
  public transform(
    value: string,
    limit: number = 250,
    skip: boolean = false
  ): string {
    if (skip) {
      return value;
    }
    limit = value.substr(0, limit).lastIndexOf(' ');
    return value.length > limit ? value.substr(0, limit) + '...' : value;
  }
}
