<div class="left">
    <div class="logo">
        <img src="../../assets/logo/logowhite.png" alt="">
    </div>
    <div class="body">
        <div class="heading">Oops !</div>
        <div class="content">
            <span>Seems like you’ve lost your way exploring the depths of the dark web. <br> Let’s get you back home?</span>
        </div>
        <a (click)="goToHome()">
            <button mat-stroked-button class="btn">
              <p>GO TO GRAPNEL HOME PAGE</p>
            </button>
        </a>
    </div>
</div>
<div class="error-img">
    <img src="../../../assets/bg_elements/bg2.png">
</div>