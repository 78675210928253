import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent implements OnInit {
  constructor(
    private router: Router,
    private authService: AuthService,
    private spinnerService: SpinnerService
  ) {}

  public menu: Array<any> = [];

  public ngOnInit(): void {
    this.spinnerService.requestStarted();
    this.authService.getCurrentUserPermissions().subscribe((res) => {
      this.menu = [
        {
          name: 'search',
          route: 'search',
          title: 'Search',
          show: true,
        },
        {
          name: 'description',
          route: 'crawlmanagement/crawllist',
          title: 'Crawl Management',
          show: res.includes('self-read-write'),
        },
        {
          name: 'supervisor_account',
          route: 'admin',
          title: 'Admin Management',
          // show: res.includes('view_all'),
          show: false
        },
      ];
      this.authService.userPermissions$.next(res);
      this.spinnerService.requestEnded();
    });
  }

  public goToHome(): void {
    this.router.navigateByUrl('search');
  }
}
