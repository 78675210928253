import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogDataModel } from 'src/app/models/dialog-data.model';

@Component({
  selector: 'app-confirm-dialog-modal',
  templateUrl: './confirm-dialog-modal.component.html',
  styleUrls: ['./confirm-dialog-modal.component.scss'],
})
export class ConfirmDialogModalComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogDataModel,
    private dialogRef: MatDialogRef<ConfirmDialogModalComponent>
  ) {}

  public accept(): void {
    this.dialogRef.close({ success: true });
  }
  public reject(): void {
    this.dialogRef.close({ success: false });
  }
}
