import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatStepperModule } from '@angular/material/stepper';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatChipsModule } from '@angular/material/chips';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSliderModule } from '@angular/material/slider';
import {
  MatDialogModule,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatExpansionModule } from '@angular/material/expansion';
import { CommonModule } from '@angular/common';
import { ConfirmDialogModalComponent } from './confirm-dialog-modal/confirm-dialog-modal.component';
import { ErrorComponent } from './error/error.component';
import { MainComponent } from '../main/main.component';
import { RouterModule } from '@angular/router';
import { ScrollContainerModule } from './scroll-container/scroll-container.module';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { SideMenuComponent } from '../main/side-menu/side-menu.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TruncatePipe } from './pipes/truncate.pipe';
import { TimeElapsedPipe } from './pipes/time-elapsed.pipe';
import { UserService } from '../services/user.service';
import { AuthService } from '../services/auth.service';
import { PaginationComponent } from './app-pagination/app-pagination.component';

@NgModule({
  declarations: [
    ConfirmDialogModalComponent,
    ErrorComponent,
    MainComponent,
    SideMenuComponent,
    TruncatePipe,
    TimeElapsedPipe,
    PaginationComponent,
  ],
  imports: [
    MatButtonModule,
    RouterModule,
    MatIconModule,
    MatMenuModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MatProgressBarModule,
  ],
  exports: [
    ReactiveFormsModule,
    FormsModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatStepperModule,
    MatButtonModule,
    MatMenuModule,
    MatToolbarModule,
    MatCardModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatChipsModule,
    MatSidenavModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSliderModule,
    MatDialogModule,
    MatTableModule,
    MatCheckboxModule,
    MatSnackBarModule,
    CommonModule,
    MatExpansionModule,
    ScrollContainerModule,
    MatButtonToggleModule,
    MatRadioModule,
    MatProgressBarModule,
    TruncatePipe,
    TimeElapsedPipe,
    PaginationComponent,
  ],
  providers: [
    MatDatepickerModule,
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
    UserService,
    AuthService,
  ],
})
export class SharedModule {}
